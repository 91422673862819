import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';
import ImageGallery from 'react-image-gallery';
import { FaFacebook, FaPhone } from 'react-icons/fa';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  {
    original: withPrefix('IMG_1318.jpg'),
    thumbnail: withPrefix('IMG_1318.jpg'),
    description: 'Учимося пакувати вел'
  },
  {
    original: withPrefix('IMG_2607.jpg'),
    thumbnail: withPrefix('IMG_2607.jpg'),
    description: 'На місце коліс ставляться проставки — для додаткової міцності'
  },
  {
    original: withPrefix('case2.jpg'),
    thumbnail: withPrefix('case2.jpg'),
    description: 'EVA Bike Pod'
  },
  {
    original: withPrefix('case.jpg'),
    thumbnail: withPrefix('case.jpg'),
    description: 'EVA Bike Pod'
  }
];

class Contacts extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div className={'opening'}>
          <img className={'responsive-image'} src={withPrefix('case.jpg')} alt="" />
          <div>
            <h1>Прокат велокейсу для перельоту</h1>
          </div>
        </div>
        <div className={'container'}>
          <p className={'text-left'}>
            <br />
            Наша команда пропонує <b>прокат велокейсів для перельоту в Києві</b> для членів команди,
            а також для індивідуальних спортсменів.
            <br />
            Чохли випробувані в безлічі поїздок і зарекомендували себе як міцні й водночас достатньо
            легкі.
            <br />
            У велокейс, крім велосипеда вміщається все необхідне екіпірування: шолом, форма, туфлі,
            ключі тощо.
            <br />
            <br />
            <div className="lead">
              Наш механік може упакувати ваш велосипед у кейс після тренування або за окремою
              домовленістю.
            </div>
            <br />
            Довжина 1200 mm, висота 907 mm, глибина 280 mm, вага 8 кг.
            <br />
            Зазвичай кейс поміщається у будь-якій машині, де можливо скласти задній ряд сидінь.
            <br />
            <br />
            <p>Щоб уникнути накладок, бронюйте кейс заздалегідь.</p>
          </p>
          <h6>Ціна прокату від 800 грн</h6>

          <FaPhone />
          <a style={{ color: 'white' }} href="tel:+380685926896">
            {' '}
            0685926896
          </a>
          <br />
          <FaFacebook />
          <a style={{ color: 'white' }} href="https://www.facebook.com/volovodroadtraining/">
            {' '}
            Facebook
          </a>

          <div>
            <ImageGallery showPlayButton={false} items={images} />
          </div>
        </div>
      </div>
    );
  }
}

const LandingPageWithFirebase = styled(withFirebase(withTrainings(Contacts)))`
  background: #0d181c;
  color: white !important;
  font-size: 15px;
  h6 {
    color: white !important;
  }
  .lead {
    background: #e30613;
    padding: 5px 10px;
    margin: 5px 0;
  }
  .opening {
    position: relative;
    > div {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 25%;
    }
    img {
      object-fit: cover;
      max-height: 100vh;
      width: 100%;
    }

    h1 {
      margin: auto;
      width: 70%;
      border: white 1px solid;
      color: white !important;
      background-color: #00000040;
      @media (max-width: 790px) {
        width: 95%;
        font-size: 26px;
      }
    }
  }
`;

export default () => (
  <Layout
    title={'Прокат велокейсу для перельоту в Києві'}
    description={'Жорсткий велосипедний чохол для перельоту'}
    seoImage={withPrefix('IMG_1318.jpg')}
    script={<script src="//code.jivosite.com/widget.js" data-jv-id="oVZl7ZwUKO" async />}
  >
    <LandingPageWithFirebase />
  </Layout>
);
